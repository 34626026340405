<template>
  <el-dialog
    title="对接第三方平台-账号同步操作注意事项"
    :visible.sync="dialogVisible"
    custom-class="ql-dialog"
    width="1000px"
    :close-on-click-modal="false"
    @closed="closeDialog"
  >
    <p>
      <span class="title">学校：</span>
      <span class="red-text">确保第三方系统与青鹿所建的学校类型一致，不一致的数据将无法同步。</span>
    </p>
    <p class="gray-text mg-t-7" style="text-indent: 44px">
      （如：青鹿为小学，第三方平台为小初高，则仅有小学的数据能同步）
    </p>
    <p class="mg-t-16">
      <span class="title">账号：</span>
      <span class="red-text">在对接前确认，是否在青鹿有历史数据需保留</span>
    </p>
    <p class="title mg-t-22">若青鹿无历史数据</p>
    <p class="text mg-t-8">则在【后台管理-第三方学校账号同步】将该学校的账号同步方式设置为“新增账号”的方式</p>
    <img class="img mg-t-14" src="@/assets/images/components/announcement/img-1.png" alt="" />
    <p class="title mg-t-12">若青鹿已有历史账号数据，且需将第三方账号与原青鹿账号做匹配，则：</p>
    <p class="step mg-t-8">Step1：在青鹿系统和第三方系统造好数据，账号匹配原则为：</p>
    <p class="text mg-t-8">教师：</p>
    <p class="text">&gt;&gt;优先级1：第三方与青鹿的教职工号</p>
    <p class="text">&gt;&gt;优先级2：第三方与青鹿的手机号</p>
    <p class="text">&gt;&gt;优先级3：第三方账号名与青鹿的教职工号</p>
    <p class="text">&gt;&gt;优先级4：第三方账号名与青鹿的手机号</p>
    <p class="text">学生：</p>
    <p class="text">&gt;&gt;优先级1：第三方与青鹿的学号</p>
    <p class="text">&gt;&gt;优先级2：第三方账号名与青鹿的学号</p>
    <p class="step mg-t-12">Step2：在【后台管理-第三方学校账号同步】将学校账号同步方式设置为“与青鹿已有账号匹配”</p>
    <img class="img" src="@/assets/images/components/announcement/img-2.png" alt="" />
    <p class="step mg-t-6">Step3：等待凌晨3~5点，系统自动同步；</p>
    <p class="text mg-t-8">
      能匹配得上的账号，第三方账号与青鹿账号会做对应，且将青鹿账号名更新为第三方的账号名，教师的任教、学生的班级数据应取双方系统的并集。无法匹配成功的第三方账号，将视作新账号同步至青鹿系统。
    </p>
    <span slot="footer">
      <el-button @click="closeDialog" size="small">取消</el-button>
      <el-button type="primary" @click="closeDialog" size="small">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { Dialog, Button } from 'element-ui';

export default {
  components: {
    [Dialog.name]: Dialog,
    [Button.name]: Button,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  watch: {
    visible(newV) {
      this.dialogVisible = newV;
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  @apply text-F1 T4B;
  font-family: PingFangSC-Semibold, PingFang SC;
}

.red-text {
  color: #ee3e3e;
}

.gray-text {
  color: #6d7171;
}

.step {
  @apply text-F1 T5B;
  font-family: PingFangSC-Medium, PingFang SC;
}

.text {
  @apply text-F1 T5;
  font-family: PingFangSC-Regular, PingFang SC;
}

.img {
  margin: 0 auto;
  display: block;
  width: 754px;
}
</style>
